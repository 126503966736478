import { FC } from 'react';
import moment from 'moment';
import Clock from 'react-live-clock';
import { Heading } from '../Heading/Heading';
import styles from './DateTime.module.css';

type DateTimeProps = {
  dateInMilliseconds?: number;
};

export const DateTime: FC<DateTimeProps> = ({ dateInMilliseconds }) => (
  <Heading level={4} className={styles.dateTime}>
    {moment(dateInMilliseconds ? dateInMilliseconds : Date.now()).format(
      'ddd D MMMM',
    )}{' '}
    <Clock format={'HH:mm:ss'} ticking={true} />
  </Heading>
);
