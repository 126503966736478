import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { FC } from 'react';
import styles from './Text.module.css';

type TextProps = {
  text?: string;
  variant: 'inherit' | Variant | undefined;
  textStyle?: keyof typeof textStyles;
  className?: string;
  onClick?: () => void;
};

type TextStyles = {
  primary: string;
  secondary: string;
  tertiary: string;
};

const textStyles: TextStyles = {
  primary: styles.primary,
  secondary: styles.secondary,
  tertiary: styles.tertiary,
};

export const Text: FC<TextProps> = ({
  children,
  text,
  variant,
  textStyle = 'primary',
  className,
  onClick,
}) => (
  <Typography
    variant={variant}
    className={`${className} ${textStyles[textStyle]}`}
    onClick={onClick}
  >
    {children || text}
  </Typography>
);
