import { useFormik } from 'formik';
import { forwardRef, useContext, useState } from 'react';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { FormInput } from 'src/components/atoms/FormInput/FormInput';
import { ADD_VIDEO, DATE, LINK, SAVE, TITLE } from 'src/constants/constants';
import { FormWrapper } from 'src/containers/FormWrapper/FormWrapper';
import { AuthContext } from 'src/providers/Auth/Auth';
import {
  VIDEO_INIT_VALUES,
  VIDEO_VALIDATION_SCHEMA,
} from 'src/constants/addVideo';
import { saveVideo } from 'src/helpers/saveVideo/saveVideo';
import { FixMe } from 'src/types';
import styles from './AddVideo.module.css';

type AddVideo = {
  closeModal: () => void;
};

export const AddVideo = forwardRef<HTMLDivElement | null, AddVideo>(
  (props, ref) => {
    const { jwt } = useContext(AuthContext);
    const [message, setMessage] = useState<string>();
    const [isError, setIsError] = useState<boolean>(false);

    const formik = useFormik({
      initialValues: VIDEO_INIT_VALUES,
      validationSchema: VIDEO_VALIDATION_SCHEMA,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: async (val) => {
        try {
          saveVideo(val, jwt);
          props.closeModal();
        } catch (err: FixMe) {
          console.error(err);
          setIsError(true);
          setMessage(err.message);
        }
      },
    });

    return (
      <FormWrapper
        title={ADD_VIDEO}
        formik={formik}
        isError={isError}
        message={message}
        containerStyle={styles.container}
        ref={ref}
      >
        <FormInput
          inputFor={TITLE}
          formik={formik}
          labelText={TITLE}
          fullWidth
          required
        />
        <FormInput
          inputFor={DATE}
          formik={formik}
          labelText={DATE}
          inputType={DATE}
          disableLabel
          fullWidth
          required
        />
        <FormInput
          inputFor={LINK}
          formik={formik}
          labelText={LINK}
          fullWidth
          required
        />
        <ButtonWrap type="submit" text={SAVE} buttonStyle="primary" fullWidth />
      </FormWrapper>
    );
  },
);

AddVideo.displayName = 'AddVideo';
