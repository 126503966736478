export const REQUIRED = 'Required';
export const NO_SIGNIN_FN = 'Unable to submit. No signIn function found.';
export const INVALID_EMAIL = 'Invalid email address';
export const EVENT_TOO_SOON_TITLE = "Oops! Session hasn't started";
export const EVENT_TOO_SOON_MSG =
  'You can join the session 20 minutes before the scheduled start time. Please come back later.';
export const NO_SIGNOUT_RESET_STATE = 'signOut() & resetState() undefined';
export const NO_EVENT = 'There are no sessions on today.';
export const NW_CLASSES = "Check out next week's sessions.";
export const NOT_FOUND = 'Oops! This page does not exist.';
export const TOO_MANY_SESSIONS =
  'Unable to add session. You can only add a maximum of 4 sessions per day.';
export const CONFIRM_DELETED_SESSION =
  'Are you sure you want to delete this session?';
export const CONFIRM_DELETED_VIDEO =
  'Are you sure you want to delete this video?';
export const ROTATE_DEVICE = 'Please rotate your device to landscape mode';
