import { forwardRef } from 'react';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { Text } from 'src/components/atoms/Text/Text';
import styles from './ModalErrorMessage.module.css';

type ModalErrorMessage = {
  title: string;
  body: string;
  onClick: () => void;
  buttonText: string;
};

export const ModalErrorMessage = forwardRef<
  HTMLDivElement | null,
  ModalErrorMessage
>((props, ref) => (
  <div className={styles.content} ref={ref}>
    <Text variant="h5" text={props.title} className={styles.title} />
    <Text variant="h6" text={props.body} className={styles.body} />
    <ButtonWrap
      onClick={props.onClick}
      text={props.buttonText}
      className={styles.button}
    />
  </div>
));

ModalErrorMessage.displayName = 'ModalErrorMessage';
