import { useState, useEffect } from 'react';

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

export const useRotateDevice = (): boolean => {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    windowWidth < 1024 ? setIsPortrait(true) : setIsPortrait(false);
  }, [windowWidth]);

  return isPortrait;
};
