import { FC } from 'react';
import { Image } from 'src/components/atoms/Image/Image';
import { Text } from 'src/components/atoms/Text/Text';
import { NOT_FOUND } from 'src/constants/errors';
import { MainLayout } from 'src/containers/MainLayout/MainLayout';
import styles from './NotFound.module.css';

export const NotFound: FC = () => (
  <MainLayout>
    <Image
      className={styles.image}
      height="100"
      width="100"
      src={process.env.PUBLIC_URL + '/404.png'}
      alt="404 Error"
    />
    <Text variant="h1" text={NOT_FOUND} className={styles.text} />
  </MainLayout>
);
