import { FC } from 'react';
import { JOIN, NEXT_CLASS } from 'src/constants/constants';
import { GenericCard } from 'src/containers/GenericCard/GenericCard';
import { Image } from 'src/components/atoms/Image/Image';
import { Event } from 'src/components/molecules/Event/Event';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { EventType } from 'src/types';
import styles from './NextEvent.module.css';
import { openLink } from 'src/helpers/openLink/openLink';

type NextEvent = {
  event: EventType;
  time: number;
  openModal: () => void;
};

export const NextEvent: FC<NextEvent> = ({ event, time, openModal }) => {
  return (
    <GenericCard title={NEXT_CLASS}>
      <>
        <Image
          height="120"
          src={event.icon}
          alt={event.title}
          className={styles.icon}
        />
        <Event title={event.title} start={event.start} end={event.end} />
        <ButtonWrap
          className={styles.button}
          onClick={() => openLink(event.link, event, time, openModal)}
          text={JOIN}
        />
      </>
    </GenericCard>
  );
};
