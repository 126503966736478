import { FC } from 'react';
import { ON_TODAY } from 'src/constants/constants';
import { GenericCard } from 'src/containers/GenericCard/GenericCard';
import { Event } from 'src/components/molecules/Event/Event';
import { EventType } from 'src/types';
import { isPastEvents } from 'src/helpers/isPastEvents/isPastEvents';
import { CardError } from 'src/components/molecules/CardError/CardError';
import { NO_EVENT, NW_CLASSES } from 'src/constants/errors';
import { ROUTES } from 'src/constants/routes';

type FullDayProps = {
  events: EventType[];
  time: number;
};

export const FullDay: FC<FullDayProps> = ({ events, time }) => {
  return (
    <GenericCard title={ON_TODAY}>
      {!isPastEvents(events, time) ? (
        events.map((item: EventType, index: number) => (
          <Event
            key={index}
            title={item.title}
            start={item.start}
            end={item.end}
          />
        ))
      ) : (
        <CardError
          message={NO_EVENT}
          link={NW_CLASSES}
          path={ROUTES.calendar.path}
        />
      )}
    </GenericCard>
  );
};
