import { FixMe } from 'src/types';

export const httpClient = async (
  url: string,
  method: string,
  header?: Record<string, unknown>,
  data?: unknown,
): Promise<Response> => {
  try {
    const response = await fetch(url, {
      headers: { 'Content-Type': 'application/json', ...header },
      method: method,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(response.statusText || response.status.toString());
    }

    return await response.json();
  } catch (err: FixMe) {
    console.error(err);
    throw err;
  }
};
