import { FC } from 'react';
import { EventType } from 'src/types';
import { JOIN, NEXT_CLASS } from 'src/constants/constants';
import { Event } from 'src/components/molecules/Event/Event';
import { GenericCard } from 'src/containers/GenericCard/GenericCard';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { openLink } from 'src/helpers/openLink/openLink';
import styles from './MultiEvents.module.css';

type MultiEvents = {
  events: EventType[];
  time: number;
  openModal: () => void;
};

export const MultiEvents: FC<MultiEvents> = ({ events, time, openModal }) => {
  return (
    <GenericCard title={NEXT_CLASS}>
      <>
        {events.map((event) => {
          return (
            <div key={event.id}>
              <Event
                title={event.title}
                start={event.start}
                end={event.end}
                className={styles.event}
              />
              <ButtonWrap
                className={styles.button}
                onClick={() => openLink(event.link, event, time, openModal)}
                text={JOIN}
              />
            </div>
          );
        })}
      </>
    </GenericCard>
  );
};
