import { Video } from 'src/types';
import * as Yup from 'yup';
import { REQUIRED } from './errors';

export const VIDEO_INIT_VALUES: Video = {
  title: '',
  date: '',
  link: '',
};

export const VIDEO_VALIDATION_SCHEMA = Yup.object({
  title: Yup.string().required(REQUIRED),
  date: Yup.string().required(REQUIRED),
  link: Yup.string().required(REQUIRED),
});
