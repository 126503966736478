import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { ChangeEvent, FC, useContext, useState } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import VideoCallIcon from '@material-ui/icons//VideoCall';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import {
  HOME,
  CALENDAR,
  ADD_EVENT,
  VIDEOS,
  ADD_VIDEO,
} from '../../../constants/constants';
import { useHistory } from 'react-router-dom';
import { isAdmin } from 'src/helpers/isAdmin/isAdmin';
import { AuthContext } from 'src/providers/Auth/Auth';
import { ModalWrapper } from 'src/containers/ModalWrapper/ModalWrapper';
import { ManageEvent } from 'src/components/organisms/ManageEvent/ManageEvent';
import styles from './TabBar.module.css';
import { AddVideo } from '../AddVideo/AddVideo';

export const TabBar: FC = () => {
  const [value, setValue] = useState('');
  const { userGroup } = useContext(AuthContext);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAddEvent, setIsAddEvent] = useState<boolean>(false);
  const [isAddVideo, setIsAddVideo] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<unknown>, newValue: string) => {
    history.push(`/${newValue}`);
    setValue(newValue);
  };

  const closeModal = () => {
    setIsModalOpen(false);

    if (isAddEvent) {
      setIsAddEvent(false);
    }

    if (isAddVideo) {
      setIsAddVideo(false);
    }
    location.reload();
  };

  const openAddEvent = () => {
    setIsModalOpen(true);
    setIsAddEvent(true);
  };

  const openAddVideo = () => {
    setIsModalOpen(true);
    setIsAddVideo(true);
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        className={styles.tabBar}
      >
        <BottomNavigationAction
          className={styles.selected}
          label={HOME}
          value="home"
          icon={<HomeIcon fontSize="large" />}
        />
        <BottomNavigationAction
          className={styles.selected}
          label={CALENDAR}
          value="calendar"
          icon={<DateRangeIcon fontSize="large" />}
        />
        <BottomNavigationAction
          className={styles.selected}
          label={VIDEOS}
          value="videos"
          icon={<VideoLibraryIcon fontSize="large" />}
        />
        {isAdmin(userGroup) && (
          <BottomNavigationAction
            className={styles.selected}
            label={ADD_EVENT}
            icon={<AddCircleOutlineIcon fontSize="large" />}
            value={location.pathname.replace(/\//g, '')}
            onClick={openAddEvent}
          />
        )}
        {isAdmin(userGroup) && (
          <BottomNavigationAction
            className={styles.selected}
            label={ADD_VIDEO}
            icon={<VideoCallIcon fontSize="large" />}
            value={location.pathname.replace(/\//g, '')}
            onClick={openAddVideo}
          />
        )}
      </BottomNavigation>
      <ModalWrapper isOpen={isModalOpen} onClose={closeModal}>
        {isAddEvent && <ManageEvent closeModal={closeModal} />}
        {isAddVideo && <AddVideo closeModal={closeModal} />}
      </ModalWrapper>
    </>
  );
};
