import { EventInitValues } from 'src/types';
import * as Yup from 'yup';
import { REQUIRED } from './errors';

export const EVENT_INIT_VALUES: EventInitValues = {
  title: '',
  date: '',
  start: '',
  end: '',
  link: '',
  category: '',
  recurring: '',
};

export const EVENT_VALIDATION_SCHEMA = Yup.object({
  title: Yup.string().required(REQUIRED),
  date: Yup.string().required(REQUIRED),
  start: Yup.string().required(REQUIRED),
  end: Yup.string().required(REQUIRED),
  link: Yup.string().required(REQUIRED),
  category: Yup.string().required(REQUIRED),
  recurring: Yup.string().required(REQUIRED),
});

export const EVENT_SELECT_OPTIONS = [
  {
    value: '/activities.png',
    label: 'Activities',
  },
  {
    value: '/social.png',
    label: 'Social',
  },
  {
    value: '/active.png',
    label: 'Staying Active',
  },
  {
    value: '/wellbeing.png',
    label: 'Wellbeing',
  },
];

export const EVENT_RADIO_OPTIONS = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];
