export const LIBERTUS = 'Libertus';
export const ADMIN = 'ADMIN';

export const HOME = 'HOME';
export const CALENDAR = 'CALENDAR';
export const ADD_EVENT = 'ADD SESSION';
export const MODIFY_EVENT = 'MODIFY SESSION';
export const VIDEOS = 'VIDEOS';
export const ADD_VIDEO = 'ADD VIDEO';
export const DELETE_VIDEO = 'DELETE VIDEO';
export const DELETE_EVENT = 'DELETE SESSION';
export const PREVIOUS_WEEK = 'PREVIOUS WEEK';
export const NEXT_WEEK = 'NEXT WEEK';

export const ON_TODAY = 'ON TODAY';
export const NEXT_CLASS = 'NEXT SESSION';

export const EMAIL = 'email';
export const PASSWORD = 'password';
export const DATE = 'date';
export const LINK = 'link';
export const CATEGORY = 'category';
export const START = 'start time';
export const END = 'end time';
export const TITLE = 'title';
export const RECURRING = 'recurring';

export const LOGIN = 'LOGIN';
export const SAVE = 'SAVE';
export const JOIN = 'JOIN';
export const LOGOUT = 'LOGOUT';
export const DELETE = 'DELETE';

export const WEEK_DAY = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];
