import { MenuItem, TextField } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { FixMe } from 'src/types';
import styles from './FormSelect.module.css';

type FormSelect = {
  selectFor: string;
  labelText?: string;
  required?: boolean;
  fullWidth?: boolean;
  isDate?: boolean;
  formik: FormikProps<FixMe>;
  className?: string;
  options: FixMe;
};

export const FormSelect: FC<FormSelect> = ({
  selectFor,
  labelText,
  required = false,
  fullWidth = false,
  formik,
  className = styles.select,
  options,
}) => {
  return (
    <TextField
      select
      variant="outlined"
      required={required}
      fullWidth={fullWidth}
      id={selectFor}
      value={formik.values.category}
      onChange={formik.handleChange}
      label={labelText}
      name={selectFor}
      error={Boolean(formik.errors.category)}
      helperText={formik.errors.category}
      className={className}
    >
      {options.map((x: FixMe, i: number) => {
        return (
          <MenuItem key={`${selectFor}-${i}`} value={x.value}>
            {x.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
