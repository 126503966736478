import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { FixMe } from 'src/types';
import styles from './FormRadio.module.css';

type FormRadio = {
  radioFor: string;
  formik: FormikProps<FixMe>;
  options: FixMe;
};

export const FormRadio: FC<FormRadio> = ({ radioFor, formik, options }) => {
  return (
    <FormControl component="fieldset" required className={styles.container}>
      <FormLabel component="legend" error={Boolean(formik.errors.recurring)}>
        {radioFor}
      </FormLabel>
      <RadioGroup
        name={radioFor}
        value={formik.values.recurring}
        onChange={formik.handleChange}
        row
      >
        {options.map((x: FixMe, i: number) => {
          return (
            <FormControlLabel
              control={<Radio className={styles.radios} />}
              label={x.label}
              value={x.value}
              key={`${radioFor}-${i}`}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText error={Boolean(formik.errors.recurring)}>
        {formik.errors.recurring}
      </FormHelperText>
    </FormControl>
  );
};
