import { EventType } from 'src/types';

export const isPastEvents = (events: EventType[], time: number): boolean => {
  let pastEvents = 0;

  events.forEach((x: EventType) => {
    if (time > x.start && time > x.end) {
      pastEvents++;
    }
  });

  return pastEvents === events.length ? true : false;
};
