import { FC } from 'react';
import { Heading } from 'src/components/atoms/Heading/Heading';
import { Link } from 'react-router-dom';
import styles from './CardError.module.css';

type CardError = {
  message: string;
  link: string;
  path: string;
};

export const CardError: FC<CardError> = ({ message, link, path }) => (
  <div className={styles.container}>
    <Heading level={2} text={message} />
    <Heading level={2}>
      <Link className={styles.link} to={path}>
        {link}
      </Link>
    </Heading>
  </div>
);
