import { FC } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import { MainLayout } from 'src/containers/MainLayout/MainLayout';
import styles from './PlayVideo.module.css';

type PlayVideoParams = {
  videoId: string;
};

export const PlayVideo: FC = () => {
  const { videoId } = useParams<PlayVideoParams>();

  return (
    <MainLayout>
      <div className={styles.container}>
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${videoId}`}
          controls={true}
          width="900px"
          height="500px"
        />
      </div>
    </MainLayout>
  );
};
