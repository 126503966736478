import { useEffect, FC, createContext, useState } from 'react';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import decode from 'jwt-decode';
import { JwtToken } from 'src/types';

type AuthContextProps = {
  isAuth: boolean;
  isLoading: boolean;
  signIn?: (username: string, password: string) => Promise<void>;
  signOut?: () => Promise<void>;
  jwt: string;
  userGroup: string;
  resetState?: () => void;
};

const authContextInitState: AuthContextProps = {
  isAuth: false,
  isLoading: true,
  jwt: '',
  userGroup: '',
};

export const AuthContext = createContext(authContextInitState);

export const Auth: FC = ({ children }) => {
  const { checkAuth, isAuth, signIn, signOut, getJwtToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [jwt, setJwt] = useState<string>('');
  const [userGroup, setUserGroup] = useState<string>('');

  useEffect(() => {
    checkAuth().then(() => setIsLoading(false));
  }, [checkAuth]);

  useEffect(() => {
    getJwtToken()
      .then((res) => {
        res ? setJwt(res) : setJwt('');
        res
          ? setUserGroup(decode<JwtToken>(res)['cognito:groups'][0])
          : setUserGroup('');
      })
      .catch((err) => console.log(err));
  }, [getJwtToken]);

  const resetState = () => {
    setIsLoading(true);
    setJwt('');
    setUserGroup('');
  };

  return (
    <AuthContext.Provider
      value={{ isAuth, isLoading, signIn, signOut, jwt, userGroup, resetState }}
    >
      {children}
    </AuthContext.Provider>
  );
};
