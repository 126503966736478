import { FC } from 'react';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { Text } from 'src/components/atoms/Text/Text';
import styles from './Confirm.module.css';

type Confirm = {
  title: string;
  containerStyle?: string;
  body: string;
  decline: () => void;
  accept: () => void;
};

export const Confirm: FC<Confirm> = ({
  title,
  body,
  containerStyle,
  decline,
  accept,
}) => (
  <div className={containerStyle}>
    <Text variant="h6" text={title} className={styles.title} />
    <Text
      variant="body1"
      text={body}
      textStyle="secondary"
      className={styles.body}
    />
    <div className={styles.btnContainer}>
      <ButtonWrap
        text="NO"
        buttonStyle="secondary"
        className={styles.button}
        onClick={decline}
      />
      <ButtonWrap
        text="YES"
        buttonStyle="primary"
        className={styles.button}
        onClick={accept}
      />
    </div>
  </div>
);
