import { Grid } from '@material-ui/core';
import { FC, useContext, useEffect, useState } from 'react';
import { Loading } from 'src/components/atoms/Loading/Loading';
import { Confirm } from 'src/components/molecules/Confirm/Confirm';
import { VideoCard } from 'src/components/organisms/VideoCard/VideoCard';
import { API_URLS } from 'src/constants/apis';
import { DELETE_VIDEO } from 'src/constants/constants';
import { CONFIRM_DELETED_VIDEO } from 'src/constants/errors';
import { MainLayout } from 'src/containers/MainLayout/MainLayout';
import { ModalWrapper } from 'src/containers/ModalWrapper/ModalWrapper';
import { deleteVideo } from 'src/helpers/deleteVideo/deleteVideo';
import { isAdmin } from 'src/helpers/isAdmin/isAdmin';
import { AuthContext } from 'src/providers/Auth/Auth';
import { FixMe } from 'src/types';
import { httpClient } from 'src/utils/httpClient/httpClient';
import styles from './Videos.module.css';

export const Videos: FC = () => {
  const [videos, setVideos] = useState<FixMe>();
  const { jwt, userGroup } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string>('');

  useEffect(() => {
    const fetchVideos = async () => {
      setVideos(
        await httpClient(API_URLS.getVideos, 'GET', {
          Authorization: `Bearer ${jwt}`,
        }),
      );
      setIsLoading(false);
    };
    fetchVideos();
  }, [jwt]);

  const openModal = (id: string) => {
    setIsModalOpen(true);
    setVideoId(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    location.reload();
  };

  const handleDelete = async () => {
    deleteVideo(jwt, videoId);
    location.reload();
  };

  return (
    <MainLayout>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Grid container className={styles.container}>
            {videos &&
              videos.map((video: FixMe, index: number) => {
                return (
                  <Grid item md={4} key={index}>
                    <VideoCard
                      video={video}
                      isAdmin={isAdmin(userGroup)}
                      onClick={() => openModal(video.id)}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <ModalWrapper isOpen={isModalOpen} onClose={closeModal}>
            <Confirm
              title={DELETE_VIDEO}
              body={CONFIRM_DELETED_VIDEO}
              decline={closeModal}
              accept={handleDelete}
              containerStyle={styles.confirm}
            />
          </ModalWrapper>
        </>
      )}
    </MainLayout>
  );
};
