import moment from 'moment';
import { EventInitValues, EventType, APIEventType } from 'src/types';
import { stringifyTime } from 'src/utils/stringifyTime/stringifyTime';

export const handleEventData = (values: EventInitValues): APIEventType => {
  return {
    title: values.title,
    start: Number(values.start.replace(':', '')),
    end: Number(values.end.replace(':', '')),
    link: values.link,
    day: moment(values.date, 'YYYY-MM-DD').format('dddd'),
    icon: values.category,
    recurring: values.recurring === 'true',
    weekNumber:
      values.recurring === 'true'
        ? 0
        : moment(values.date, 'YYYY-MM-DD').week(),
  };
};

export const reverseHandleEventData = (event: EventType): EventInitValues => {
  return {
    title: event.title,
    start: stringifyTime(event.start),
    end: stringifyTime(event.end),
    link: event.link,
    date: moment(event.day, 'dddd').format('YYYY-MM-DD'),
    category: event.icon,
    recurring: event.recurring.toString(),
  };
};
