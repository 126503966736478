export const API_URLS = {
  getEvents:
    process.env.NODE_ENV === 'development' ? '/api/events' : '/prod/api/events',
  saveEvent:
    process.env.NODE_ENV === 'development' ? '/api/events' : '/prod/api/events',
  getDailyEvents:
    process.env.NODE_ENV === 'development'
      ? '/api/events/day/'
      : '/prod/api/events/day/',
  getWeeklyEvents:
    process.env.NODE_ENV === 'development'
      ? '/api/events/week/'
      : '/prod/api/events/week/',
  updateEvent:
    process.env.NODE_ENV === 'development'
      ? '/api/events/update/'
      : '/prod/api/events/update/',
  deleteEvent:
    process.env.NODE_ENV === 'development'
      ? '/api/events/delete/'
      : '/prod/api/events/delete/',
  getVideos:
    process.env.NODE_ENV === 'development' ? '/api/videos' : '/prod/api/videos',
  saveVideo:
    process.env.NODE_ENV === 'development' ? '/api/videos' : '/prod/api/videos',
  deleteVideo:
    process.env.NODE_ENV === 'development'
      ? '/api/videos/delete/'
      : '/prod/api/videos/delete/',
};
