import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

type UseAuth = {
  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  checkAuth: () => Promise<void>;
  getJwtToken: () => Promise<string | undefined>;
  isAuth: boolean;
};

export const useAuth = (): UseAuth => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);

  const checkAuth = async () => {
    return await Auth.currentSession()
      .then(() => setIsAuth(true))
      .catch(() => setIsAuth(false));
  };

  const signIn = async (username: string, password: string): Promise<void> => {
    return await Auth.signIn({ username, password })
      .then(() => {
        setIsAuth(true);
        history.push(ROUTES.home.path);
      })
      .catch((err) => {
        setIsAuth(false);
        history.push(ROUTES.login.path);
        throw new Error(err.message);
      });
  };

  const getJwtToken = async () => {
    let jwtToken;

    await Auth.currentSession().then((res) => {
      jwtToken = res.getIdToken().getJwtToken();
    });

    return jwtToken;
  };

  const signOut = async () => {
    await Auth.signOut();
    setIsAuth(false);
    history.push(ROUTES.login.path);
  };

  return { signIn, signOut, checkAuth, isAuth, getJwtToken };
};
