import { Grid } from '@material-ui/core';
import { FC, useContext, useEffect, useState } from 'react';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { Heading } from 'src/components/atoms/Heading/Heading';
import { Loading } from 'src/components/atoms/Loading/Loading';
import { Event } from 'src/components/molecules/Event/Event';
import { ManageEvent } from 'src/components/organisms/ManageEvent/ManageEvent';
import { API_URLS } from 'src/constants/apis';
import { NEXT_WEEK, PREVIOUS_WEEK, WEEK_DAY } from 'src/constants/constants';
import { GenericCard } from 'src/containers/GenericCard/GenericCard';
import { MainLayout } from 'src/containers/MainLayout/MainLayout';
import { ModalWrapper } from 'src/containers/ModalWrapper/ModalWrapper';
import { getTime } from 'src/helpers/getTime/getTime';
import { isAdmin } from 'src/helpers/isAdmin/isAdmin';
import { AuthContext } from 'src/providers/Auth/Auth';
import { EventType, FixMe } from 'src/types';
import { httpClient } from 'src/utils/httpClient/httpClient';
import styles from './Calendar.module.css';

export const Calendar: FC = () => {
  const [events, setEvents] = useState<FixMe>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { jwt, userGroup } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeSession, setActiveSession] = useState<FixMe>();
  const [currentWeek, setCurrentWeek] = useState<number>(0);
  const { weekNumber, monday, friday } = getTime(currentWeek);

  useEffect(() => {
    const fetchEvents = async () => {
      setEvents(
        await httpClient(
          `${API_URLS.getWeeklyEvents}${weekNumber + currentWeek}`,
          'GET',
          {
            Authorization: `Bearer ${jwt}`,
          },
        ),
      );
      setIsLoading(false);
    };
    fetchEvents();
  }, [jwt, weekNumber, currentWeek]);

  const closeModal = () => {
    setIsModalOpen(false);
    location.reload();
  };

  const openModal = (session: EventType) => {
    setIsModalOpen(true);
    setActiveSession(session);
  };

  const increaseCurrentWeek = () => {
    setCurrentWeek((x) => x + 1);
  };

  const decreaseCurrentWeek = () => {
    setCurrentWeek((x) => x - 1);
  };

  return (
    <MainLayout>
      <Heading text={`${monday} - ${friday}`} className={styles.title} />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Grid className={styles.container}>
            {WEEK_DAY.map((day, index) => {
              return (
                <GenericCard className={styles.card} key={index} title={day}>
                  {events &&
                    events[day].map((session: EventType, index: number) => {
                      return (
                        <Event
                          key={index}
                          title={session.title}
                          start={session.start}
                          end={session.end}
                          level={3}
                          className={isAdmin(userGroup) ? styles.event : ''}
                          onClick={() => openModal(session)}
                          isAdmin={isAdmin(userGroup)}
                        />
                      );
                    })}
                </GenericCard>
              );
            })}
          </Grid>
          <ModalWrapper isOpen={isModalOpen} onClose={closeModal}>
            <ManageEvent
              closeModal={closeModal}
              session={activeSession}
              isUpdate={true}
            />
          </ModalWrapper>
          <Grid className={styles.btnContainer}>
            {currentWeek > 0 && (
              <ButtonWrap
                text={PREVIOUS_WEEK}
                className={styles.previous}
                onClick={decreaseCurrentWeek}
              />
            )}
            {currentWeek < 3 && (
              <ButtonWrap
                text={NEXT_WEEK}
                className={styles.next}
                onClick={increaseCurrentWeek}
              />
            )}
          </Grid>
        </>
      )}
    </MainLayout>
  );
};
