import { FC, HTMLAttributes } from 'react';

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level?: number;
  className?: string;
  text?: string;
}

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export const Heading: FC<HeadingProps> = ({
  level = 1,
  className,
  children,
  text,
  ...rest
}) => {
  const Tag = `h${level}` as HeadingType;

  return (
    <Tag className={className} {...rest}>
      {text || children}
    </Tag>
  );
};
