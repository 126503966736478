import { FC } from 'react';
import { Image } from 'src/components/atoms/Image/Image';
import { Text } from 'src/components/atoms/Text/Text';
import { ROTATE_DEVICE } from 'src/constants/errors';
import styles from './RotateDevice.module.css';

export const RotateDevice: FC = () => (
  <div className={styles.container}>
    <Image
      src="/rotate.png"
      alt="Rotate device"
      height="150"
      width="150"
      className={styles.img}
    />
    <Text variant="h1" text={ROTATE_DEVICE} className={styles.text} />
  </div>
);
