import { TextField } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { FixMe } from 'src/types';

type FormInputProps = {
  inputFor: string;
  inputType?: string;
  labelText: string;
  required?: boolean;
  fullWidth?: boolean;
  disableLabel?: boolean;
  formik: FormikProps<FixMe>;
  className?: string;
};

export const FormInput: FC<FormInputProps> = ({
  inputFor,
  inputType,
  labelText,
  required = false,
  fullWidth = false,
  disableLabel = false,
  formik,
  className,
}) => {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      type={inputType}
      required={required}
      fullWidth={fullWidth}
      id={inputFor}
      value={formik.values[inputFor]}
      onChange={formik.handleChange}
      label={labelText}
      name={inputFor}
      error={Boolean(formik.errors[inputFor])}
      helperText={formik.errors[inputFor]}
      className={className}
      InputLabelProps={disableLabel ? { shrink: true } : {}}
    />
  );
};
