import { FC } from 'react';
import styles from './GenericCard.module.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Text } from 'src/components/atoms/Text/Text';

type GenericCardProps = {
  title: string;
  className?: string;
};

export const GenericCard: FC<GenericCardProps> = ({
  children,
  title,
  className = styles.container,
}) => (
  <Card className={className}>
    <Text className={styles.heading} variant="h1" text={title.toUpperCase()} />
    <CardContent>{children}</CardContent>
  </Card>
);
