export const ROUTES = {
  login: {
    description: 'Login',
    path: '/',
  },
  logout: {
    description: 'Logout',
    path: '/',
  },
  home: {
    description: 'Home',
    path: '/home',
  },
  calendar: {
    description: 'Calendar',
    path: '/calendar',
  },
  videos: {
    description: 'Videos',
    path: '/videos',
  },
  playVideo: {
    description: 'Play Video',
    path: '/videos/:videoId',
  },
};
