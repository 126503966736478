import { EventType } from 'src/types';
import { increaseTime } from 'src/utils/increaseTime/increaseTime';

export const openLink = (
  link: string,
  event: EventType,
  time: number,
  openModal: () => void,
): void => {
  increaseTime(time, 20) >= event.start && time < event.end
    ? window.open(link, '_blank')
    : openModal();
};
