import moment from 'moment';

type GetTime = {
  today: string;
  time: number;
  weekNumber: number;
  monday: string;
  friday: string;
};

export const getTime = (currentWeek?: number): GetTime => {
  const today = moment(new Date()).format('dddd');
  const time = Number(moment(new Date()).format('Hmm'));
  const weekNumber = moment(new Date(), 'DD-MM-YYYY').week();
  const monday = moment()
    .week(currentWeek ? currentWeek + weekNumber : weekNumber)
    .day('Monday')
    .format('dddd Do');
  const friday = moment()
    .week(currentWeek ? currentWeek + weekNumber : weekNumber)
    .day('Friday')
    .format('dddd Do MMMM');

  return { today, time, weekNumber, monday, friday };
};
