import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { FC } from 'react';
import { DateTime } from 'src/components/atoms/DateTime/DateTime';
import { Image } from '../../atoms/Image/Image';
import styles from './NavBar.module.css';

type NavBarProps = {
  dateTime?: boolean;
};

export const NavBar: FC<NavBarProps> = ({ dateTime = false }) => {
  return (
    <AppBar position="static" className={styles.nav}>
      <Toolbar>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <a href="/home">
              <Image
                height="50"
                width="150"
                className={styles.logo}
                src={process.env.PUBLIC_URL + '/libertus.png'}
                alt="Libertus Logo"
              />
            </a>
          </Grid>

          <Grid item>{dateTime ? <DateTime /> : null}</Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
