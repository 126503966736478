import { Card, CardContent } from '@material-ui/core';
import { FC } from 'react';
import Image from 'material-ui-image';
import styles from './VideoCard.module.css';
import { Text } from 'src/components/atoms/Text/Text';
import moment from 'moment';
import { useHistory } from 'react-router';

type Video = {
  id: string;
  created: number;
  title: string;
  date: string;
  link: string;
  img: string;
  videoId: string;
};

type VideoCard = {
  video: Video;
  isAdmin: boolean;
  onClick: () => void;
};

export const VideoCard: FC<VideoCard> = ({ video, isAdmin, onClick }) => {
  const history = useHistory();

  const redirect = (id: string) => {
    history.push(`/videos/${id}`);
  };

  return (
    <>
      <Card className={styles.container}>
        <CardContent>
          <Image
            src={video.img}
            alt={video.title}
            cover={true}
            imageStyle={{ height: '150px' }}
            style={{ padding: '0px', height: '150px' }}
            onClick={() => redirect(video.videoId)}
          />
          <Text
            className={isAdmin ? styles.admin : styles.title}
            variant="h1"
            text={video.title.toUpperCase()}
            onClick={isAdmin ? onClick : undefined}
          />
          <Text
            variant="body1"
            text={moment(video.date).format('dddd - Do MMMM YYYY')}
            textStyle="secondary"
          />
        </CardContent>
      </Card>
    </>
  );
};
