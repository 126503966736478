import { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { awsConfigure } from 'src/helpers/awsConfigure';
import { Auth } from 'src/providers/Auth/Auth';
import { PrivateRoute } from 'src/components/atoms/PrivateRoute/PrivateRoute';
import { PublicRoute } from 'src/components/atoms/PublicRoute/PublicRoute';
import { ROUTES } from 'src/constants/routes';
import { Home } from 'src/pages/Home/Home';
import { CssBaseline } from '@material-ui/core';
import { Calendar } from 'src/pages/Calendar/Calendar';
import * as config from '../../config/env-config.json';
import './App.css';
import { Login } from 'src/pages/Login/Login';
import { NotFound } from 'src/pages/NotFound/NotFound';
import { Videos } from 'src/pages/Videos/Videos';
import { PlayVideo } from 'src/pages/PlayVideo/PlayVideo';

awsConfigure(config);

export const App: FC = () => {
  return (
    <Router>
      <CssBaseline />
      <Auth>
        <Switch>
          <PublicRoute path={ROUTES.login.path} exact component={Login} />
          <PrivateRoute path={ROUTES.home.path} exact component={Home} />
          <PrivateRoute
            path={ROUTES.calendar.path}
            exact
            component={Calendar}
          />
          <PrivateRoute path={ROUTES.videos.path} exact component={Videos} />
          <PrivateRoute
            path={ROUTES.playVideo.path}
            exact
            component={PlayVideo}
          />
          <Route component={NotFound} />
        </Switch>
      </Auth>
    </Router>
  );
};
