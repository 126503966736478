import { FC, MouseEventHandler } from 'react';
import { Button } from '@material-ui/core';
import styles from './ButtonWrap.module.css';

type ButtonProps = {
  text?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset' | undefined;
  fullWidth?: boolean;
  buttonStyle?: keyof typeof buttonStyles;
  className?: string;
};

type ButtonStyles = {
  primary: string;
  secondary: string;
  tertiary: string;
};

const buttonStyles: ButtonStyles = {
  primary: styles.primary,
  secondary: styles.secondary,
  tertiary: styles.tertiary,
};

export const ButtonWrap: FC<ButtonProps> = ({
  children,
  text,
  onClick,
  fullWidth = false,
  type,
  buttonStyle,
  className,
  ...rest
}) => (
  <Button
    onClick={onClick}
    type={type}
    fullWidth={fullWidth}
    variant="contained"
    className={`${className} ${
      buttonStyle ? buttonStyles[buttonStyle] : styles.primary
    }`}
    {...rest}
  >
    {children || text}
  </Button>
);
