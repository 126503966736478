import { FC, useContext } from 'react';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { NavBar } from 'src/components/molecules/NavBar/NavBar';
import { RotateDevice } from 'src/components/molecules/RotateDevice/RotateDevice';
import { TabBar } from 'src/components/organisms/TabBar/TabBar';
import { LOGOUT } from 'src/constants/constants';
import { NO_SIGNOUT_RESET_STATE } from 'src/constants/errors';
import { useRotateDevice } from 'src/hooks/useRotateDevice/useRotateDevice';
import { AuthContext } from 'src/providers/Auth/Auth';
import styles from './MainLayout.module.css';

export const MainLayout: FC = ({ children }) => {
  const { signOut, resetState } = useContext(AuthContext);
  const isPortrait = useRotateDevice();

  return (
    <>
      <NavBar dateTime={true} />
      <ButtonWrap
        buttonStyle="tertiary"
        text={LOGOUT}
        onClick={
          signOut && resetState
            ? () => {
                signOut();
                resetState();
              }
            : () => console.error(NO_SIGNOUT_RESET_STATE)
        }
        className={styles.logout}
      />
      {isPortrait ? <RotateDevice /> : children}
      <TabBar />
    </>
  );
};
