import { FC, useContext, useEffect, useState } from 'react';
import { Heading } from 'src/components/atoms/Heading/Heading';
import { MainLayout } from 'src/containers/MainLayout/MainLayout';
import { Grid } from '@material-ui/core';
import { NextEvent } from 'src/components/organisms/NextEvent/NextEvent';
import { MultiEvents } from 'src/components/organisms/MultiEvents/MultiEvents';
import { FullDay } from 'src/components/organisms/FullDay/FullDay';
import { httpClient } from 'src/utils/httpClient/httpClient';
import { API_URLS } from 'src/constants/apis';
import { Loading } from 'src/components/atoms/Loading/Loading';
import { getNextEvent } from 'src/helpers/getNextEvent/getNextEvent';
import { ModalWrapper } from 'src/containers/ModalWrapper/ModalWrapper';
import { ModalErrorMessage } from 'src/components/molecules/ModalErrorMessage/ModalErrorMessage';
import { EVENT_TOO_SOON_MSG, EVENT_TOO_SOON_TITLE } from 'src/constants/errors';
import { AuthContext } from 'src/providers/Auth/Auth';
import styles from './Home.module.css';
import { FixMe } from 'src/types';
import { getEventsWithSameStartTime } from 'src/helpers/getEventsWithSameStartTime/getEventsWithSameStartTime';
import { getTime } from 'src/helpers/getTime/getTime';

export const Home: FC = () => {
  const [events, setEvents] = useState<FixMe>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { jwt } = useContext(AuthContext);
  const [isMultipleEvents, setIsMultipleEvents] = useState<boolean>(false);
  const { today, time } = getTime();

  useEffect(() => {
    const fetchEvents = async () => {
      setEvents(
        await httpClient(`${API_URLS.getDailyEvents}${today}`, 'GET', {
          Authorization: `Bearer ${jwt}`,
        }),
      );
    };

    if (today && jwt) {
      fetchEvents();
    }
  }, [today, jwt]);

  useEffect(() => {
    if (events && time) {
      const multiEvents = getEventsWithSameStartTime(events, time);

      if (
        multiEvents.length > 0 &&
        time >= multiEvents[0].start &&
        time < multiEvents[0].end
      ) {
        setIsMultipleEvents(true);
      }
    }
  }, [events, time]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <MainLayout>
      <Heading />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={styles.cardContainer}
      >
        {!!events && time ? (
          <>
            {isMultipleEvents ? (
              <MultiEvents
                events={getEventsWithSameStartTime(events, time)}
                time={time}
                openModal={openModal}
              />
            ) : (
              <NextEvent
                event={getNextEvent(events, time, today)}
                time={time}
                openModal={openModal}
              />
            )}
            <FullDay events={events} time={time} />
          </>
        ) : (
          <Loading />
        )}
      </Grid>
      <ModalWrapper isOpen={isModalOpen} onClose={closeModal}>
        <ModalErrorMessage
          title={EVENT_TOO_SOON_TITLE}
          body={EVENT_TOO_SOON_MSG}
          buttonText="OK"
          onClick={closeModal}
        />
      </ModalWrapper>
    </MainLayout>
  );
};
