import { Modal } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { FC } from 'react';

type ModalWrapper = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  isOpen: boolean;
  onClose: () => void;
};

export const ModalWrapper: FC<ModalWrapper> = ({
  children,
  isOpen,
  onClose,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <DialogContent>{children}</DialogContent>
    </Modal>
  );
};
