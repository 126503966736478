import { FC } from 'react';

type ImageProps = {
  height?: string;
  width?: string;
  className?: string;
  src: string;
  alt: string;
};

export const Image: FC<ImageProps> = ({
  height,
  width,
  className,
  src,
  alt,
}) => (
  <img
    src={src}
    alt={alt}
    width={width}
    height={height}
    className={className}
  />
);
