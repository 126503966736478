import { Grid } from '@material-ui/core';
import { FC } from 'react';
import { Heading } from 'src/components/atoms/Heading/Heading';
import { stringifyTime } from 'src/utils/stringifyTime/stringifyTime';
import styles from './Event.module.css';

type EventProps = {
  title: string;
  start: number;
  end: number;
  className?: string;
  level?: number;
  isAdmin?: boolean;
  onClick?: () => void;
};

export const Event: FC<EventProps> = ({
  title,
  start,
  end,
  className,
  onClick,
  level = 2,
  isAdmin = false,
}) => (
  <div className={`${className} ${styles.container}`}>
    <Grid>
      <Heading
        className={styles.heading}
        text={title.toUpperCase()}
        level={level}
        onClick={isAdmin ? onClick : undefined}
      />
    </Grid>
    <Grid>
      <Heading
        className={styles.heading}
        text={`${stringifyTime(start)} - ${stringifyTime(end)}`}
        level={level}
        onClick={isAdmin ? onClick : undefined}
      />
    </Grid>
  </div>
);
