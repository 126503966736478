import { API_URLS } from 'src/constants/apis';
import { httpClient } from 'src/utils/httpClient/httpClient';

export const deleteEvent = async (
  jwt: string,
  id: string,
): Promise<Response> => {
  const res = await httpClient(`${API_URLS.deleteEvent}${id}`, 'DELETE', {
    Authorization: `Bearer ${jwt}`,
  });

  return res;
};
