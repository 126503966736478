import { API_URLS } from 'src/constants/apis';
import { APIEventType } from 'src/types';
import { httpClient } from 'src/utils/httpClient/httpClient';

export const updateEvent = async (
  data: APIEventType,
  jwt: string,
  id: string,
): Promise<Response> => {
  const res = await httpClient(
    `${API_URLS.updateEvent}${id}`,
    'PUT',
    {
      Authorization: `Bearer ${jwt}`,
    },
    data,
  );

  return res;
};
