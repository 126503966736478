import { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/providers/Auth/Auth';
import { Loading } from '../Loading/Loading';

export const PrivateRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const { isAuth, isLoading } = useContext(AuthContext);

  return !isLoading ? (
    isAuth ? (
      <Route {...rest} component={component} />
    ) : (
      <Redirect to={ROUTES.login.path} />
    )
  ) : (
    <Loading />
  );
};
