import { Container } from '@material-ui/core';
import { FC } from 'react';
import { Text } from 'src/components/atoms/Text/Text';
import { FixMe } from 'src/types';
import styles from './FormWrapper.module.css';

type FormWrapper = {
  children: FixMe;
  title: string;
  formik: FixMe;
  isError: boolean;
  message: string | undefined;
  containerStyle?: string;
  contentStyle?: string;
  ref?: FixMe;
};

export const FormWrapper: FC<FormWrapper> = ({
  children,
  title,
  formik,
  isError,
  message,
  containerStyle,
  contentStyle,
  ref,
}) => {
  return (
    <Container
      component="main"
      maxWidth="xs"
      className={containerStyle}
      ref={ref}
    >
      <div className={`${contentStyle} ${styles.content}`}>
        <Text variant="h5" text={title} className={styles.title} />
        <form className={styles.form} onSubmit={formik.handleSubmit} noValidate>
          {children}
          {!!message && (
            <Text
              variant="body1"
              className={isError ? styles.error : styles.message}
              text={message}
            />
          )}
        </form>
      </div>
    </Container>
  );
};
