import { EventType } from 'src/types';

export const getNextEvent = (
  events: EventType[],
  time: number,
  day: string | undefined,
): EventType => {
  const nextEvents: EventType[] = [];

  events.forEach((x: EventType) => {
    if (time >= x.start && time < x.end) {
      nextEvents.push(x);
    }

    if (time < x.start) {
      nextEvents.push(x);
    }

    if (day !== x.day) {
      nextEvents.push(x);
    }
  });

  return nextEvents.length > 0 ? nextEvents[0] : events[events.length - 1];
};
