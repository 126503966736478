import { useFormik } from 'formik';
import { forwardRef, useContext, useState } from 'react';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { FormInput } from 'src/components/atoms/FormInput/FormInput';
import { FormRadio } from 'src/components/molecules/FormRadio/FormRadio';
import { FormSelect } from 'src/components/molecules/FormSelect/FormSelect';
import {
  EVENT_INIT_VALUES,
  EVENT_RADIO_OPTIONS,
  EVENT_SELECT_OPTIONS,
  EVENT_VALIDATION_SCHEMA,
} from 'src/constants/manageEvent';
import {
  ADD_EVENT,
  CATEGORY,
  DATE,
  DELETE,
  DELETE_EVENT,
  END,
  LINK,
  MODIFY_EVENT,
  RECURRING,
  SAVE,
  START,
  TITLE,
} from 'src/constants/constants';
import {
  CONFIRM_DELETED_SESSION,
  TOO_MANY_SESSIONS,
} from 'src/constants/errors';
import { FormWrapper } from 'src/containers/FormWrapper/FormWrapper';
import { saveEvent } from 'src/helpers/saveEvent/saveEvent';
import { AuthContext } from 'src/providers/Auth/Auth';
import { EventType, FixMe } from 'src/types';
import {
  handleEventData,
  reverseHandleEventData,
} from '../../../helpers/handleEventData/handleEventData';
import styles from './ManageEvent.module.css';
import { updateEvent } from 'src/helpers/updateEvent/updateEvent';
import { ModalWrapper } from 'src/containers/ModalWrapper/ModalWrapper';
import { Confirm } from 'src/components/molecules/Confirm/Confirm';
import { deleteEvent } from 'src/helpers/deleteEvent/deleteEvent';

type ManageEvent = {
  closeModal: () => void;
  session?: EventType;
  isUpdate?: boolean;
};

export const ManageEvent = forwardRef<HTMLDivElement | null, ManageEvent>(
  (props, ref) => {
    const [message, setMessage] = useState<string>();
    const [isError, setIsError] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { jwt } = useContext(AuthContext);

    const formik = useFormik({
      initialValues: props.isUpdate
        ? reverseHandleEventData(props.session!)
        : EVENT_INIT_VALUES,
      validationSchema: EVENT_VALIDATION_SCHEMA,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: async (val) => {
        try {
          props.isUpdate
            ? await updateEvent(handleEventData(val), jwt, props.session!.id)
            : await saveEvent(handleEventData(val), jwt);
          props.closeModal();
        } catch (err: FixMe) {
          setIsError(true);
          err.message === 'Method Not Allowed' || err.message === '405'
            ? setMessage(TOO_MANY_SESSIONS)
            : setMessage(err.message);
        }
      },
    });

    const toggleModal = () => {
      isModalOpen ? setIsModalOpen(false) : setIsModalOpen(true);
    };

    const handleDelete = async () => {
      deleteEvent(jwt, props.session!.id);
      location.reload();
    };

    return (
      <>
        <FormWrapper
          title={props.isUpdate ? MODIFY_EVENT : ADD_EVENT}
          formik={formik}
          isError={isError}
          message={message}
          containerStyle={styles.container}
          ref={ref}
        >
          <FormInput
            inputFor={TITLE}
            formik={formik}
            labelText={TITLE}
            fullWidth
            required
          />
          <FormInput
            inputFor={DATE}
            formik={formik}
            labelText={DATE}
            inputType={DATE}
            disableLabel
            fullWidth
            required
          />
          <FormInput
            inputFor="start"
            inputType="time"
            formik={formik}
            labelText={START}
            className={styles.startTime}
            disableLabel
            required
          />
          <FormInput
            inputFor="end"
            inputType="time"
            formik={formik}
            labelText={END}
            className={styles.endTime}
            required
            disableLabel
          />
          <FormInput
            inputFor={LINK}
            formik={formik}
            labelText={LINK}
            fullWidth
            required
          />
          <FormSelect
            selectFor={CATEGORY}
            formik={formik}
            options={EVENT_SELECT_OPTIONS}
            labelText={CATEGORY}
            fullWidth
            required
          />
          <FormRadio
            radioFor={RECURRING}
            formik={formik}
            options={EVENT_RADIO_OPTIONS}
          />
          <div className={styles.btnContainer}>
            {props.isUpdate && (
              <ButtonWrap
                text={DELETE}
                buttonStyle="secondary"
                className={styles.button}
                onClick={toggleModal}
              />
            )}
            <ButtonWrap
              type="submit"
              text={SAVE}
              buttonStyle="primary"
              fullWidth={props.isUpdate ? false : true}
              className={props.isUpdate ? styles.button : ''}
            />
          </div>
        </FormWrapper>
        <ModalWrapper isOpen={isModalOpen} onClose={toggleModal}>
          <Confirm
            title={DELETE_EVENT}
            body={CONFIRM_DELETED_SESSION}
            decline={toggleModal}
            accept={handleDelete}
            containerStyle={styles.container}
          />
        </ModalWrapper>
      </>
    );
  },
);

ManageEvent.displayName = 'ManageEvent';
