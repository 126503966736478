import { API_URLS } from 'src/constants/apis';
import { Video } from 'src/types';
import { httpClient } from 'src/utils/httpClient/httpClient';

export const saveVideo = async (
  data: Video,
  jwt: string,
): Promise<Response> => {
  const res = await httpClient(
    API_URLS.saveVideo,
    'POST',
    {
      Authorization: `Bearer ${jwt}`,
    },
    data,
  );

  return res;
};
