export type ENVCONFIG = {
  USER_POOL_ID: string;
  USER_POOL_CLIENT_ID: string;
  CLOUDFRONT_DOMAIN_NAME: string;
  REGION: string;
};

export type AWSCONFIG = {
  Auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    authenticationFlowType: string;
    cookieStorage: {
      domain: string;
      path: string;
      expires: number;
      secure: boolean;
    };
  };
};

export const dynamicAWSConfig = (config: ENVCONFIG): AWSCONFIG => {
  const parsedConfig = JSON.parse(JSON.stringify(config)).default;

  return {
    Auth: {
      region: parsedConfig.REGION,
      userPoolId: parsedConfig.USER_POOL_ID,
      userPoolWebClientId: parsedConfig.USER_POOL_CLIENT_ID,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      cookieStorage: {
        domain: 'liberactive.co.uk',
        path: '/',
        expires: 1,
        secure: true,
      },
    },
  };
};
