import moment from 'moment';

export const increaseTime = (time: number, increment: number): number => {
  let timeString;

  time.toString().length === 3
    ? (timeString = '0' + time)
    : (timeString = time.toString());

  const mTime = moment(timeString, 'HHmm');

  return Number(moment(mTime).add(increment, 'minutes').format('HHmm'));
};
