import { EventType } from 'src/types';
import { isNil } from 'src/utils/isNil/isNil';

export const getEventsWithSameStartTime = (
  events: EventType[],
  time: number,
): EventType[] => {
  const eventsAtSameTime: EventType[] = [];

  if (!isNil(events)) {
    events.filter((x: EventType, i: number) => {
      if (
        events.indexOf(events[i + 1]) >= 0 &&
        x.start === events[i + 1].start &&
        (time <= x.start || (time >= x.start && time < x.end))
      ) {
        eventsAtSameTime.push(x);
        eventsAtSameTime.push(events[i + 1]);
      }
    });
  }

  return eventsAtSameTime.length === 2 ? eventsAtSameTime : [];
};
