import { FC, useContext, useState } from 'react';
import {
  LOGIN_FORM_INIT_VALUES,
  LOGIN_FORM_VALIDATION_SCHEMA,
} from 'src/constants/login';
import { EMAIL, LOGIN, PASSWORD } from 'src/constants/constants';
import { AuthContext } from 'src/providers/Auth/Auth';
import { NO_SIGNIN_FN } from 'src/constants/errors';
import { useFormik } from 'formik';
import { ButtonWrap } from 'src/components/atoms/ButtonWrap/ButtonWrap';
import { FormInput } from 'src/components/atoms/FormInput/FormInput';
import { FormWrapper } from 'src/containers/FormWrapper/FormWrapper';
import styles from './LoginForm.module.css';
import { FixMe } from 'src/types';

export const LoginForm: FC = () => {
  const { signIn } = useContext(AuthContext);
  const [message, setMessage] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: LOGIN_FORM_INIT_VALUES,
    validationSchema: LOGIN_FORM_VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (val) => {
      if (signIn) {
        try {
          await signIn(val.email, val.password);
        } catch (err: FixMe) {
          setIsError(true);
          setMessage(err.message);
        }
      } else {
        console.error(NO_SIGNIN_FN);
      }
    },
  });

  return (
    <FormWrapper
      title={LOGIN}
      formik={formik}
      isError={isError}
      message={message}
      contentStyle={styles.content}
    >
      <FormInput
        inputFor={EMAIL}
        required={true}
        formik={formik}
        fullWidth={true}
        labelText={EMAIL}
      />
      <FormInput
        inputFor={PASSWORD}
        required={true}
        formik={formik}
        fullWidth={true}
        labelText={PASSWORD}
        inputType={PASSWORD}
      />
      <ButtonWrap
        type="submit"
        fullWidth={true}
        text={LOGIN}
        buttonStyle="primary"
      />
    </FormWrapper>
  );
};
