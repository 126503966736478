import { FC } from 'react';
import { NavBar } from 'src/components/molecules/NavBar/NavBar';
import { LoginForm } from 'src/components/organisms/LoginForm/LoginForm';

export const Login: FC = () => (
  <>
    <NavBar />
    <LoginForm />
  </>
);
