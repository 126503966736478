import { LoginFormProps } from 'src/types';
import * as Yup from 'yup';
import { INVALID_EMAIL, REQUIRED } from './errors';

export const LOGIN_FORM_INIT_VALUES: LoginFormProps = {
  email: '',
  password: '',
};

export const LOGIN_FORM_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email(INVALID_EMAIL).required(REQUIRED),
  password: Yup.string().required(REQUIRED),
});
